.crop-container {
    position: relative;
    width: 100%;
    height: 400px;
    background: #333;
}

.filter-item-selected, .filter-item-selected > a {
    background-color: #556ee6;
    color:#fff !important;
    padding: 1px;
    border-radius: 1px;
}

.container-images img {
    width: 100%;
}
.container-images button {
    position: absolute;
    float: top;
    right: 1px;
}
.variant-image {
    width: 100px;
}
.container-variant-images button {
    font-size: 30px;
   color: #000000; 
}

.container-variant-images button.selected {
    font-size: 30px;
    position: absolute;
    z-index: 1;
    color: #e0ff84;
    top: 10px;
    right: 26px;
}

.border-bottom {
    border-bottom: #0529dd solid 2px;
}


.border-card {
    margin-top: 10px;
    border: #4a4c54 solid 1px;
    border-radius: 10px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}